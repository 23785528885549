@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    body {
        @apply bg-background
    }
    .active {
        @apply font-black text-primary
    }
    .primary-btn {
        @apply py-2 px-8 bg-primary text-white uppercase rounded-md font-bold transition-all duration-500
    }
    .primary-btn:hover {
        @apply bg-secondary transition-all duration-500
    }
    .my-title {
        @apply uppercase font-bold py-8 text-3xl text-primary text-center
    }
}

@layer Component {
    .input-range {
        @apply select-none appearance-none bg-primary h-3 rounded bg-opacity-50 w-full
    }
}

.my-title::before {
    content: " ";
    position: relative;
    display: block;
    background: #c1c1c1;
    top: 47px;
    height: 3px;
    width: 45px;
    margin: auto;
    border-radius: 3px;
}

.my-title::after {
    content: " ";
    position: relative;
    display: block;
    background: #c1c1c1;
    top: 15px;
    height: 3px;
    width: 80px;
    margin: auto;
    border-radius: 3px;
}

input[type='range'] {
    -webkit-appearance: none;
    height: 10px;
    border: none;
    border-radius: 5px;
    background: #141313;
    /* background-image: linear-gradient(#d48844, #d44); */
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #6f6f6f;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
}

input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}